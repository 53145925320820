$primary-color: #1a282b;
$secondary-color: #de4d59;
$black: #000;
$white: #fff;
//Media Queries
@mixin media($point) {
  $media-xs: "(max-width: 600px)";
  $media-sm: "(min-width: 768px)";
  $media-sm-max: "(max-width: 767px)";
  $media-md: "(min-width: 992px)";
  $media-md-max: "(max-width: 991px)";
  $media-lg: "(min-width: 1200px)";
  $media-lg-max: "(max-width: 1199px)";
  $media-xlg: "(min-width: 1300px)";
  $media-xlg-max: "(max-width: 1299px)";

  @if $point == xs {
    @media #{$media-xs} { @content; }
  }
  @else if $point == sm {
    @media #{$media-sm} { @content; }
  }

  @else if $point == md {
    @media #{$media-md}  { @content; }
  }

  @else if $point == sm-max {
    @media #{$media-sm-max} { @content; }
  }
  @else if $point == md-max {
    @media #{$media-md-max}  { @content; }
  }
  @else if $point == lg {
    @media #{$media-lg}  { @content; }
  }
  @else if $point == xlg {
    @media #{$media-xlg}  { @content; }
  }
  @else if $point == sm-max {
    @media #{$media-sm-max} { @content; }
  }
  @else if $point == md-max {
    @media #{$media-md-max}  { @content; }
  }
  @else if $point == lg-max {
    @media #{$media-lg-max}  { @content; }
  }
  @else if $point == xlg-max {
    @media #{$media-xlg-max}  { @content; }
  }
}
body {
  font-size: 20px;
  color: $primary-color;
  @include media(md-max){
    font-size: 12px;
  }
  .header-section {
    background-image: url("/assets/images/background-image-1.jpeg");
    background-size: contain;
    background-position: right bottom;
    background-repeat: no-repeat;
    padding-top: 30px;
    padding-bottom: 30px;
    @include media(md-max) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    @include media(sm-max) {
      padding-bottom: 150px;
      background-position: bottom;
    }
    .container {
      .logo-box {
        height: 64px;
        .logo {
          height: 100%;
        }
      }
      .header-content {
        .text-area {
          padding: 60px 0;
          @include media(md-max) {
            padding: 30px 0;
          }
          @include media(sm-max) {
            text-align: center;
          }
          .title {
            padding-bottom: 34px;
            @include media(md-max) {
              font-size: 16px;
              padding-bottom: 14px;
            }
            .txt-highlight {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }
  .hero-section {
    padding-top: 100px;
    padding-bottom: 50px;
    @include media(md-max) {
      padding-top: 10px;
      padding-bottom: 25px;
    }
    .container {
      .content {
        display: flex;
        align-items: center;
        @include media(sm-max) {
          flex-direction: column-reverse;
        }
        .text-area {
          padding: 60px 0;
          @include media(md-max) {
            padding: 30px 0;
          }
          @include media(sm-max) {
            text-align: center;
          }
          .title {
            padding-bottom: 34px;
            @include media(md-max) {
              font-size: 16px;
              padding-bottom: 14px;
            }
            .txt-highlight {
              color: $secondary-color;
            }
          }
        }
        .btn {
          background-color: black;
          color: $white;
          padding: 14px 24px;
          font-size: 18px;
          &.play-store-btn {
            margin-right: 30px;
          }
          @include media(md-max) {
            padding: 10px 20px;
            font-size: 12px;
          }
        }
        .img-box {
          svg {
            height: 100%;
            width: 100%;
            @include media(md-max) {
              max-height: 250px;
            }
          }

        }
      }
    }
  }
  .contact-section {
    background-color: #f5dadb;
    color:#ffffff;
    position: relative;
    margin-bottom: 50px;
    @include media(sm-max) {
      text-align: center;
      background-color: #cb444b;
    }
  &:before{
    position: absolute;
    content: "";
    background-color: #cb444b;
    width: 50%;
    left: 0px;
    top:0px;
    bottom: 0px;
    z-index: 0;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    @include media(sm-max) {
     display: none;
      text-align: center;
    }
  }
      .content {
        margin: 0;
        .contact-header {
          padding: 20px;
          //border-top-right-radius: 60px;
          //border-bottom-right-radius: 60px;
          @include media(sm-max) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            text-align: center;
          }
        }
        .contact-detail {
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            display: block;
            max-width: 300px;
            font-size: 14px;
            width: 100%;
            background: #cb444b;
            padding: 10px;
            text-align: center;
            border-radius: 6px;
            color: #ffffff;
            font-weight: 700;
            margin-bottom: 10px;
          }
        }
      }
  }
  .footer {
    padding: 18px 60px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    @include media(md-max) {
      font-size: 12px;
      display: block;
      padding: 12px 20px;
      text-align: center;
    }
    .footer-menu {
      a {
        color: $primary-color !important;

        &.terms-and-condition {
          margin-right: 25px;
          @include media(md-max) {
            margin-right: 15px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.terms-and-conditions, .privacy{
  padding: 50px 10px 100px !important;
  color: #595959 !important;
  line-height: 1.8;
  font-weight: 400;
  h4 {
    font-size: 26px;
    font-weight: 700;
    color: $black!important;
  }
  .sub-title {
    font-size: 17px !important;
    font-weight: 700;
    padding-bottom: 30px;
  }
  .heading-1 {
    font-size: 19px !important;
    color: $black !important;
    font-weight: 700;
  }
  .heading-2 {
    font-size: 17px !important;
    color: $black!important;
  }
  p {
    font-size: 16px !important;
  }
  .link{
    color: #01166b !important;
    font-size: 16px !important;
    word-break: break-word !important;
  }
  .m-l-20{
    margin-left: 20px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
}
.img-responsive{
        width: 100%;
    height: auto;
}